
import { defineComponent, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import UserService from "@/services/UserService"
import { useConfirm } from "primevue/useconfirm"

export default defineComponent({
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed"],
  setup(props, { emit }) {
    const form = ref()
    const confirm = useConfirm()
    const router = useRouter()
    const userService = new UserService()
    const mailSent = ref(false)

    function onSaveChanges(userData: any) {
      userService.updateUser(props.user.id, userData).then(() => {
        // Do nothing
        emit("value-changed")
        form.value.onDone()
      })
    }

    function onDelete() {
      userService.deleteUser(props.user.id).then(() => {
        router.push({ name: "users" })
      })
    }

    function onConfirmDelete(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Delete oprganization",
        message: "Are you sure you want to remove this user?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: onDelete,
      })
    }

    function onConfirmPasswordReset(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Delete oprganization",
        message: 'Send reset link to "' + props.user.email + '"?',
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-info",
        accept: requestPasswordReset,
      })
    }

    function requestPasswordReset() {
      userService.requestPasswordReset(props.user.email).then(() => {
        mailSent.value = true
      })
    }

    return {
      form,
      mailSent,
      onSaveChanges,
      onConfirmDelete,
      requestPasswordReset,
      onConfirmPasswordReset,
    }
  },
})
